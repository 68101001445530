import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Olympic Games Women RR",
      edition: 2021,
      what: "Race data",
      updated: "2021-07-23 13:05:26",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      Australia: {
        teamId: "Australia",
        teamUciCode: "",
        teamName: "Australia",
        teamNationCode: "AUS",
      },
      Austria: {
        teamId: "Austria",
        teamUciCode: "",
        teamName: "Austria",
        teamNationCode: "AUT",
      },
      Belarus: {
        teamId: "Belarus",
        teamUciCode: "",
        teamName: "Belarus",
        teamNationCode: "BLR",
      },
      Belgium: {
        teamId: "Belgium",
        teamUciCode: "",
        teamName: "Belgium",
        teamNationCode: "BEL",
      },
      Canada: {
        teamId: "Canada",
        teamUciCode: "",
        teamName: "Canada",
        teamNationCode: "CAN",
      },
      Chile: {
        teamId: "Chile",
        teamUciCode: "",
        teamName: "Chile",
        teamNationCode: "CHI",
      },
      China: {
        teamId: "China",
        teamUciCode: "",
        teamName: "China",
        teamNationCode: "CHN",
      },
      Colombia: {
        teamId: "Colombia",
        teamUciCode: "",
        teamName: "Colombia",
        teamNationCode: "COL",
      },
      "Costa Rica": {
        teamId: "Costa Rica",
        teamUciCode: "",
        teamName: "Costa Rica",
        teamNationCode: "CRC",
      },
      Cuba: {
        teamId: "Cuba",
        teamUciCode: "",
        teamName: "Cuba",
        teamNationCode: "CUB",
      },
      Cyprus: {
        teamId: "Cyprus",
        teamUciCode: "",
        teamName: "Cyprus",
        teamNationCode: "CYP",
      },
      "Czech Republic": {
        teamId: "Czech Republic",
        teamUciCode: "",
        teamName: "Czech Republic",
        teamNationCode: "CZE",
      },
      Denmark: {
        teamId: "Denmark",
        teamUciCode: "",
        teamName: "Denmark",
        teamNationCode: "DEN",
      },
      Eritrea: {
        teamId: "Eritrea",
        teamUciCode: "",
        teamName: "Eritrea",
        teamNationCode: "ERI",
      },
      Ethiopia: {
        teamId: "Ethiopia",
        teamUciCode: "",
        teamName: "Ethiopia",
        teamNationCode: "ETH",
      },
      France: {
        teamId: "France",
        teamUciCode: "",
        teamName: "France",
        teamNationCode: "FRA",
      },
      Germany: {
        teamId: "Germany",
        teamUciCode: "",
        teamName: "Germany",
        teamNationCode: "GER",
      },
      "Great Britain": {
        teamId: "Great Britain",
        teamUciCode: "",
        teamName: "Great Britain",
        teamNationCode: "GBR",
      },
      Israel: {
        teamId: "Israel",
        teamUciCode: "",
        teamName: "Israel",
        teamNationCode: "ISR",
      },
      Italy: {
        teamId: "Italy",
        teamUciCode: "",
        teamName: "Italy",
        teamNationCode: "ITA",
      },
      Japan: {
        teamId: "Japan",
        teamUciCode: "",
        teamName: "Japan",
        teamNationCode: "JPN",
      },
      Lithuania: {
        teamId: "Lithuania",
        teamUciCode: "",
        teamName: "Lithuania",
        teamNationCode: "LTU",
      },
      Luxembourg: {
        teamId: "Luxembourg",
        teamUciCode: "",
        teamName: "Luxembourg",
        teamNationCode: "LUX",
      },
      Mexico: {
        teamId: "Mexico",
        teamUciCode: "",
        teamName: "Mexico",
        teamNationCode: "MEX",
      },
      Namibia: {
        teamId: "Namibia",
        teamUciCode: "",
        teamName: "Namibia",
        teamNationCode: "NAM",
      },
      Netherlands: {
        teamId: "Netherlands",
        teamUciCode: "",
        teamName: "Netherlands",
        teamNationCode: "NED",
      },
      Norway: {
        teamId: "Norway",
        teamUciCode: "",
        teamName: "Norway",
        teamNationCode: "NOR",
      },
      Paraguay: {
        teamId: "Paraguay",
        teamUciCode: "",
        teamName: "Paraguay",
        teamNationCode: "PAR",
      },
      Poland: {
        teamId: "Poland",
        teamUciCode: "",
        teamName: "Poland",
        teamNationCode: "POL",
      },
      Russia: {
        teamId: "Russia",
        teamUciCode: "",
        teamName: "Russia",
        teamNationCode: "RUS",
      },
      Slovenia: {
        teamId: "Slovenia",
        teamUciCode: "",
        teamName: "Slovenia",
        teamNationCode: "SLO",
      },
      "South Africa": {
        teamId: "South Africa",
        teamUciCode: "",
        teamName: "South Africa",
        teamNationCode: "RSA",
      },
      "South Korea": {
        teamId: "South Korea",
        teamUciCode: "",
        teamName: "South Korea",
        teamNationCode: "KOR",
      },
      Spain: {
        teamId: "Spain",
        teamUciCode: "",
        teamName: "Spain",
        teamNationCode: "ESP",
      },
      Switzerland: {
        teamId: "Switzerland",
        teamUciCode: "",
        teamName: "Switzerland",
        teamNationCode: "SUI",
      },
      Thailand: {
        teamId: "Thailand",
        teamUciCode: "",
        teamName: "Thailand",
        teamNationCode: "THA",
      },
      "Trinidad & Tobago": {
        teamId: "Trinidad & Tobago",
        teamUciCode: "",
        teamName: "Trinidad & Tobago",
        teamNationCode: "TRI",
      },
      Ukraine: {
        teamId: "Ukraine",
        teamUciCode: "",
        teamName: "Ukraine",
        teamNationCode: "UKR",
      },
      USA: {
        teamId: "USA",
        teamUciCode: "",
        teamName: "USA",
        teamNationCode: "USA",
      },
      Uzbekistan: {
        teamId: "Uzbekistan",
        teamUciCode: "",
        teamName: "Uzbekistan",
        teamNationCode: "UZB",
      },
    },
    riders: {
      90975: {
        id: 90975,
        startno: 60,
        firstName: "Maria Jose",
        lastName: "Vargas",
        nationCode: "CRC",
        nationName: "Costa Rica",
        birthDate: "1996-02-15",
        teamName: "Costa Rica",
        teamId: "Costa Rica",
        stillInTheRace: "Y",
      },
      89419: {
        id: 89419,
        startno: 25,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 36,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      90596: {
        id: 90596,
        startno: 33,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      89937: {
        id: 89937,
        startno: 54,
        firstName: "Omer",
        lastName: "Shapira",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-09-09",
        teamName: "Israel",
        teamId: "Israel",
        stillInTheRace: "Y",
      },
      89828: {
        id: 89828,
        startno: 28,
        firstName: "Coryn",
        lastName: "Rivera",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-08-26",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 18,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      89463: {
        id: 89463,
        startno: 47,
        firstName: "Rasa",
        lastName: "Leleivyte",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-07-22",
        teamName: "Lithuania",
        teamId: "Lithuania",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 45,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      89416: {
        id: 89416,
        startno: 4,
        firstName: "Marianne",
        lastName: "Vos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-05-13",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      90884: {
        id: 90884,
        startno: 52,
        firstName: "Tereza",
        lastName: "Neumanova",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1998-08-09",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 39,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      96725: {
        id: 96725,
        startno: 13,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      90073: {
        id: 90073,
        startno: 46,
        firstName: "Jutatip",
        lastName: "Maneephan",
        nationCode: "THA",
        nationName: "Thailand",
        birthDate: "1988-07-08",
        teamName: "Thailand",
        teamId: "Thailand",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 7,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      89503: {
        id: 89503,
        startno: 57,
        firstName: "Olga",
        lastName: "Zabelinskaya",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "1980-05-10",
        teamName: "Uzbekistan",
        teamId: "Uzbekistan",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 19,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89504: {
        id: 89504,
        startno: 51,
        firstName: "Vera",
        lastName: "Looser",
        nationCode: "NAM",
        nationName: "Namibia",
        birthDate: "1993-10-28",
        teamName: "Namibia",
        teamId: "Namibia",
        stillInTheRace: "Y",
      },
      91260: {
        id: 91260,
        startno: 49,
        firstName: "Paula",
        lastName: "Patino",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      92704: {
        id: 92704,
        startno: 53,
        firstName: "Agua Marina",
        lastName: "Espinola",
        nationCode: "PAR",
        nationName: "Paraguay",
        birthDate: "1996-03-31",
        teamName: "Paraguay",
        teamId: "Paraguay",
        stillInTheRace: "Y",
      },
      92496: {
        id: 92496,
        startno: 58,
        firstName: "Selam Amha",
        lastName: "Gerefiel",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1997-09-03",
        teamName: "Ethiopia",
        teamId: "Ethiopia",
        stillInTheRace: "Y",
      },
      89513: {
        id: 89513,
        startno: 32,
        firstName: "Carla",
        lastName: "Oberholzer",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1987-01-14",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      125733: {
        id: 125733,
        startno: 23,
        firstName: "Anna",
        lastName: "Shackley",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-17",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      89795: {
        id: 89795,
        startno: 27,
        firstName: "Chlo\u00e9",
        lastName: "Dygert",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1997-01-01",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      96036: {
        id: 96036,
        startno: 17,
        firstName: "Sarah",
        lastName: "Gigante",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-10-06",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      57567: {
        id: 57567,
        startno: 64,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamName: "Japan",
        teamId: "Japan",
        stillInTheRace: "Y",
      },
      92703: {
        id: 92703,
        startno: 24,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      89407: {
        id: 89407,
        startno: 43,
        firstName: "Karol-Ann",
        lastName: "Canuel",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-04-18",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      93562: {
        id: 93562,
        startno: 3,
        firstName: "Demi",
        lastName: "Vollering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-15",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89778: {
        id: 89778,
        startno: 62,
        firstName: "Antri",
        lastName: "Christoforou",
        nationCode: "CYP",
        nationName: "Cyprus",
        birthDate: "1992-04-02",
        teamName: "Cyprus",
        teamId: "Cyprus",
        stillInTheRace: "Y",
      },
      100156: {
        id: 100156,
        startno: 67,
        firstName: "Ah Reum",
        lastName: "Na",
        nationCode: "KOR",
        nationName: "South Korea",
        birthDate: "1990-03-24",
        teamName: "South Korea",
        teamId: "South Korea",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 12,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      92485: {
        id: 92485,
        startno: 35,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      89716: {
        id: 89716,
        startno: 6,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      89712: {
        id: 89712,
        startno: 30,
        firstName: "Ruth",
        lastName: "Winder",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1993-07-08",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89534: {
        id: 89534,
        startno: 50,
        firstName: "Alena",
        lastName: "Amialiusik",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1989-02-06",
        teamName: "Belarus",
        teamId: "Belarus",
        stillInTheRace: "Y",
      },
      90976: {
        id: 90976,
        startno: 55,
        firstName: "Lizbeth Yareli",
        lastName: "Salazar Vazquez",
        nationCode: "MEX",
        nationName: "Mexico",
        birthDate: "1996-12-08",
        teamName: "Mexico",
        teamId: "Mexico",
        stillInTheRace: "Y",
      },
      92784: {
        id: 92784,
        startno: 61,
        firstName: "Jiajun",
        lastName: "Sun",
        nationCode: "CHN",
        nationName: "China",
        birthDate: "1996-07-29",
        teamName: "China",
        teamId: "China",
        stillInTheRace: "Y",
      },
      92142: {
        id: 92142,
        startno: 38,
        firstName: "Stine",
        lastName: "Borgli",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1990-07-04",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      89554: {
        id: 89554,
        startno: 10,
        firstName: "Cecilie Uttrup",
        lastName: "Ludwig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-08-23",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89555: {
        id: 89555,
        startno: 31,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      89408: {
        id: 89408,
        startno: 22,
        firstName: "Lizzie",
        lastName: "Deignan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1988-12-18",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      98831: {
        id: 98831,
        startno: 66,
        firstName: "Teniel",
        lastName: "Campbell",
        nationCode: "TRI",
        nationName: "Trinidad & Tobago",
        birthDate: "1997-09-23",
        teamName: "Trinidad & Tobago",
        teamId: "Trinidad & Tobago",
        stillInTheRace: "Y",
      },
      89538: {
        id: 89538,
        startno: 16,
        firstName: "Tiffany",
        lastName: "Cromwell",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-07-06",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      98814: {
        id: 98814,
        startno: 63,
        firstName: "Hiromi",
        lastName: "Kaneko",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1980-09-09",
        teamName: "Japan",
        teamId: "Japan",
        stillInTheRace: "Y",
      },
      92781: {
        id: 92781,
        startno: 59,
        firstName: "Mossana",
        lastName: "Debesay",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1993-11-25",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      89886: {
        id: 89886,
        startno: 21,
        firstName: "Julie",
        lastName: "Van De Velde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-02",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89434: {
        id: 89434,
        startno: 2,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 56,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamName: "Cuba",
        teamId: "Cuba",
        stillInTheRace: "Y",
      },
      89418: {
        id: 89418,
        startno: 26,
        firstName: "Anna",
        lastName: "Plichta",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1992-02-10",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      89536: {
        id: 89536,
        startno: 11,
        firstName: "Lisa",
        lastName: "Brennauer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-06-08",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      89450: {
        id: 89450,
        startno: 34,
        firstName: "Ane",
        lastName: "Santesteban",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-12-12",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      89443: {
        id: 89443,
        startno: 5,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      89801: {
        id: 89801,
        startno: 29,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-29",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89882: {
        id: 89882,
        startno: 48,
        firstName: "Anna",
        lastName: "Kiesenhofer",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-02-14",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      89410: {
        id: 89410,
        startno: 41,
        firstName: "Christine",
        lastName: "Majerus",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1987-02-25",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
      106425: {
        id: 106425,
        startno: 65,
        firstName: "Catalina Anais",
        lastName: "Soto Campos",
        nationCode: "CHI",
        nationName: "Chile",
        birthDate: "2001-04-08",
        teamName: "Chile",
        teamId: "Chile",
        stillInTheRace: "Y",
      },
      89950: {
        id: 89950,
        startno: 37,
        firstName: "Katrine",
        lastName: "Aalerud",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-04",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      92093: {
        id: 92093,
        startno: 9,
        firstName: "Emma",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-07-26",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 40,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      89545: {
        id: 89545,
        startno: 14,
        firstName: "Trixi",
        lastName: "Worrack",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1981-09-28",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      90072: {
        id: 90072,
        startno: 42,
        firstName: "Valeriya",
        lastName: "Kononenko",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1990-05-14",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      91184: {
        id: 91184,
        startno: 15,
        firstName: "Grace",
        lastName: "Brown",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-07-07",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 8,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      89488: {
        id: 89488,
        startno: 44,
        firstName: "Alison",
        lastName: "Jackson",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-12-14",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      89883: {
        id: 89883,
        startno: 20,
        firstName: "Lotte",
        lastName: "Kopecky",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-11-10",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89403: {
        id: 89403,
        startno: 1,
        firstName: "Anna",
        lastName: "van der Breggen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-04-18",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Olympics RR Women"
  const raceID = 9096

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
